import { call, put, select, takeLatest } from 'redux-saga/effects'
import { authAction as actions } from './index'
import { getIsGainBroker, getIsGainUserType } from '../user-service'
import { selectUserType } from './auth-selectors'
import {
  EMPLOYEE,
  FUTURES_TRADING_SOFTWARE_SUBMENU,
  INTRODUCING_BROKER,
  PROFILE_MANAGEMENT_SUBMENU,
  PROFILE_MANAGEMENT_SUBMENU_BROKER,
} from 'app/components/ProfileManagement/Helper/constants'
import { ILinkDetails } from 'app/components/NavigationPanel/Interfaces/navigationPanelInterface'

export function* userExpiredRedirection() {
  try {
    yield put(actions.userExpired())
  } catch (err) {}
}

export function* getIsGainUser_API() {
  const sideNaveDisplay = process.env.REACT_APP_GAIN_USER_ONLY === 'true'
  const getUserType = yield select(selectUserType)
  try {
    const isGainCustomer: boolean = yield call(getIsGainUserType)
    const isGainBroker: boolean = yield call(getIsGainBroker)
    const profileTabs: ILinkDetails[] = isGainBroker
      ? PROFILE_MANAGEMENT_SUBMENU_BROKER
      : PROFILE_MANAGEMENT_SUBMENU
    const futuresTradingTabs: ILinkDetails[] = isGainCustomer
      ? FUTURES_TRADING_SOFTWARE_SUBMENU
      : []
    const enabledTabsForUser: ILinkDetails[] = [...profileTabs, ...futuresTradingTabs]
    yield put(actions.storeIsGainUser(isGainCustomer && sideNaveDisplay))
    yield put(actions.setNavigationBasedOnUser(enabledTabsForUser.filter(x => x.isEnabled)))
    if (
      (getUserType.toLocaleUpperCase() === EMPLOYEE ||
        getUserType.toLocaleUpperCase() === INTRODUCING_BROKER) &&
      isGainBroker
    ) {
      yield put(actions.isEmployeeBroker(true))
    } else {
      yield put(actions.isEmployeeBroker(false))
    }
  } catch (err) {}
}

/**
 * Root saga manages watcher lifecycle
 */
export function* authorizationFromSaga() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.userTokenExpired.type, userExpiredRedirection)
  yield takeLatest(actions.getIsGainUser.type, getIsGainUser_API)
}
